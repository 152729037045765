import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Img } from "wmk-image";
import MediaAssetBlock from "../components/ui/MediaAssetBlock";

const MediaAssetTest = ({ data }) => {
  // const data = useStaticQuery(query)
  const webImage = new Img(data.data.webVersion);
  const printImage = new Img(data.data.printVersion);
  // console.log("data", data);
  // console.log("webImage", webImage);

  return (
    <Container>
      <Row>
        <Col md={4}>
          <MediaAssetBlock
            title={data.title}
            webImage={data.data.webVersion}
            printImage={data.data.printVersion}
          />
        </Col>
        <Col md={4}>
          <MediaAssetBlock
            title={data.title}
            webImage={data.data.webVersion}
            printImage={data.data.printVersion}
          />
        </Col>
        <Col md={4}>
          <MediaAssetBlock
            title={data.title}
            webImage={data.data.webVersion}
            printImage={data.data.printVersion}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MediaAssetTest;

export const query = graphql`
  {
    data: contentfulBlockMediaAssets(title: { eq: "Image test" }) {
      title
      webVersion {
        filename
        size
        ...NodeImageFields
      }
      printVersion {
        filename
        size
        ...NodeImageFields
      }
      videoDescription
      videoTitle
      videoUrl
    }
  }
`;
